/* Positioning the arrows */
.slick-prev {
  left: 30px;
  z-index: 2;
}

.slick-next {
  right: 30px;
}

.slick-next:before,
.slick-prev:before {
  color: black;
}

@media (max-width: 600px) {
  .slick-prev:before,
  .slick-next:before {
    display: none;
  }
}
