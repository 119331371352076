.section-container {
  min-height: 80vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center the content vertically */
  align-items: center; /* Center the content horizontally */
  overflow: hidden; /* Hide the overflow content */
  background: #d9fcf5;
  border-radius: 0px 0px 24px 24px;
}
