@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #b6b6b6;
}

body {
  margin: 0;
  font-family: "Poppins", "IBM Plex Sans", sans-serif; /* Use Inter as primary, Poppins as fallback */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  overflow-x: hidden;
}

#root {
  width: 100vw;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,
button,
input {
  text-decoration: none;
  border: none;
  outline: none;
}

@keyframes flip {
  0%,
  80% {
    transform: rotateY(360deg);
  }
}
